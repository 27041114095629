import router from "@/router";
import { GetterTree } from "vuex";
import { IRootState } from "../types";
import { IArticle, IHelpState } from "./types";

export const getters: GetterTree<IHelpState, IRootState> = {
  articles(state): IHelpState["articles"] {
    return state.articles;
  },
  article:
    (state) =>
    (slug: string): IArticle | null => {
      return (
        state.articles.find((articles) => {
          return articles.slug === slug;
        }) || null
      );
    },
  nextArticle:
    (state) =>
    (slug: string): IArticle | null => {
      const index = state.articles.findIndex((articles) => {
        return articles.slug === slug;
      });
      // Do not add item if current article is last item
      return index + 1 !== state.articles.length
        ? state.articles[index + 1]
        : null;
    },
  previousArticle:
    (state) =>
    (slug: string): IArticle | null => {
      const index = state.articles.findIndex((articles) => {
        return articles.slug === slug;
      });
      // Do not subtract item if current article is first item
      return index !== 0 ? state.articles[index - 1] : null;
    },
  title:
    (state) =>
    (slug: string): string => {
      const article = state.articles.find((articles) => {
        return articles.slug === slug;
      });
      return article ? article.title : "…";
    },
};
