import { Module } from "vuex";
import { IRootState } from "../types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IHelpState } from "./types";

const state: IHelpState = {
  articles: [
    {
      contents: [
        {
          body: "Our AI tracks the impact of more than 28,000+ museums, galleries, art fairs, and exhibitions globally. We analyze the impact they have on the trajectory of hundreds of thousands of artists born after 1900.",
          image: {
            alt: "Wondeur animated gif",
            height: "",
            src: "help/Knowledge_v2.2.gif",
            width: "",
          },
          subtitle: "",
          tips: [],
        },
      ],
      slug: "welcome",
      title: "Welcome to Wondeur",
    },
    {
      contents: [
        {
          body: "Add artists manually or import a list of artists. Wondeur will analyze artists in Smart Portfolio by acclaim, growth, growth percentile over the last 5 years and 2 years.",
          image: {
            alt: "Example Portfolio page with artist list, growth percentages, and bubble chart",
            height: "",
            src: "help/1.png",
            width: "",
          },
          subtitle: "",
          tips: [
            "Tip: Growth percentile: “P80%” means that 80% of artists in the same category (ie. Emerging/Underrepresented, Established, Celebrated or Star Artist) had a lower growth over the last 2Y/5Y period.",
          ],
        },
      ],
      slug: "overview",
      title: "Smart Portfolio: Overview",
    },
    {
      contents: [
        {
          body: "Use the map to quickly visualize the performance of a group of artists in comparison to one another based on cultural acclaim (vertically) and acceleration (horizontally).",
          image: {
            alt: "Bubble Chart map displaying artist dots on a grid. The top-right represents the highest growth artist, while the bottom left represents new and emerging artists.",
            height: "",
            src: "help/2.png",
            width: "",
          },
          subtitle: "",
          tips: [
            "Tip: The higher the artist is on the map, the higher the artist acclaim is. The more an artist is positioned on the right, the faster the artist’s acceleration is in the last 5 years or 2 years.",
          ],
        },
      ],
      slug: "map",
      title: "Smart Portfolio: The Map",
    },
    {
      contents: [
        {
          body: "Click any artist’s name to access the Artist Profile, giving you an instant overview of an artist’s analysis including acceleration, trajectory, and artworks in museum collections. You can work on an object-level by adding artworks and artwork details.",
          image: {
            alt: "Individual group artist page. Displays artist stats summary and artist cards which feature more detailed information.",
            height: "",
            src: "help/slide-4-artist-profile.png",
            width: "",
          },
          subtitle: "",
          tips: [
            "Tip: A red dot next to an institution signifies that the institution has had a significant impact on the acclaim of the artist. This is determined exclusively by the AI’s analysis.",
          ],
        },
      ],
      slug: "artist-profile",
      title: "Smart Portfolio: Artist Profile",
    },
    {
      contents: [
        {
          body: "Download and populate a CSV template to import a list of artists. Year of last appraisal and value of the last appraisal are optional.",
          image: {
            alt: "Add artist to portfolio: Enter the artist name or download a template and import a CSV with artist data",
            height: "",
            src: "help/4.png",
            width: "",
          },
          subtitle: "",
          tips: [
            "Tip: Flags are indicators of real-time AI analysis that detect a significant change in value in the last 2 or 5 years or since the year of the last valuation.",
          ],
        },
      ],
      slug: "importing-data",
      title: "Smart Portfolio: Instant Upload",
    },
    {
      contents: [
        {
          body: "Questions or Feedback? Email [info@wondeur.ai](mailto:info@wondeur.ai).",
          image: {
            alt: "Wondeur Logo Gif",
            height: "",
            src: "help/wondeur-logo.gif",
            width: "",
          },
          subtitle: "",
          tips: [],
        },
      ],
      slug: "contact-us",
      title: "Contact Us",
    },
  ],
};

const namespaced: boolean = true;

export const help: Module<IHelpState, IRootState> = {
  getters,
  mutations,
  namespaced,
  state,
};
