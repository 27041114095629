<script setup lang="ts">
  import { computed, defineProps } from "vue";
  import { sizeFormatter } from "@/helpers/sizeFormatter";
  import { editionFormatter } from "@/helpers/editionFormatter";
  import HighlightInput from "@/components/portfolio/unmatched/HighlightInput.vue";
  import type { IPortfolioUploadFields } from "@/types/portfolio-upload-fields";
  
  interface Props {
    params: {
      data: IPortfolioUploadFields;
    };
  };
  
  const props = defineProps<Props>();
  
  const percent = (input: number) => {
    if (!input) return "";
    
    return `(${(input * 100).toFixed(2)}%)`;
  }
  
  const size = computed(() => sizeFormatter({
    height: props.params.data.size.match.height || null,
    width: props.params.data.size.match.width || null,
    depth: props.params.data.size.match.depth || null,
    unit: props.params.data.size.match.unit || null,
  }));
  
  const edition = computed(() => editionFormatter({
    number: props.params.data.edition.match.number || null,
    total: props.params.data.edition.match.total || null,
  }));
</script>

<template lang="pug">
  .px-10.py-2.leading-loose.whitespace-normal
    .flex.flex-col.gap-6.bg-slate-100.px-7.pt-5.pb-6.rounded
      div
        h2.uppercase.font-bold.text-slate-600 Artist Name
        p 
          span.font-bold Input: 
          highlight-input(
            :input="params.data.artist.input"
            :indexes="params.data.artist.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ params.data.artist.match.match }} {{ percent(params.data.artist.match.score) }}
      div
        h2.uppercase.font-bold.text-slate-600 Medium
        p
          span.font-bold Input: 
          highlight-input(
            :input="params.data.medium.input"
            :indexes="params.data.medium.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ params.data.medium.match.match }}
      div
        h2.uppercase.font-bold.text-slate-600 Size
        p
          span.font-bold Input: 
          highlight-input(
            :input="params.data.size.input"
            :indexes="params.data.size.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ size }}
      div
        h2.uppercase.font-bold.text-slate-600 Created Year
        p
          span.font-bold Input: 
          highlight-input(
            :input="params.data.created_year.input"
            :indexes="params.data.created_year.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ params.data.created_year.match.match }}
      div
        h2.uppercase.font-bold.text-slate-600 Edition
        p
          span.font-bold Input: 
          highlight-input(
            :input="params.data.edition.input"
            :indexes="params.data.edition.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ edition }}
      div
        h2.uppercase.font-bold.text-slate-600 Title
        p
          span.font-bold Input: 
          highlight-input(
            :input="params.data.title.input"
            :indexes="params.data.title.match.indexes"
          )
        p
          span.font-bold Matched: 
          | {{ params.data.title.match.match }}
  </template>
