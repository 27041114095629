import { IPortfolio } from "@/types/portfolio";
import { IGroupArtist } from "@/types/group-artists";
import { ActionTree } from "vuex";
import { IRootState } from "../types";
import { IPortfolioState } from "./types";

export const actions: ActionTree<IPortfolioState, IRootState> = {
  addArtist: async ({ state, rootState }, params: { artist_uuid: number }) => {
    if (state.portfolio) {
      return fetch(
        `${rootState.apiEndpoint}web/v1/groups/${state.portfolio.uuid}/group_artists.json`,
        {
          ...rootState.fetchOptions,
          method: "POST",
          body: JSON.stringify(params),
        }
      )
        .then(() => true)
        .catch(() => false);
    }

    return false;
  },
  addMember(
    { commit, rootState },
    params: {
      group_id: number;
      user_id: number;
    }
  ): void {
    fetch(
      `${rootState.apiEndpoint}web/v1/groups/${params.group_id}/group_memberships.json`,
      {
        ...rootState.fetchOptions,
        method: "POST",
        body: JSON.stringify({ user_id: params.user_id }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        commit("addMember", data.data.group_membership);
      })
      .catch(() => {});
  },
  fetch(
    { commit, state, rootState },
    args: {
      portfolioId?: number | string;
      metaPortfolioUuid?: string;
      reset: boolean;
    }
  ): any {
    if (
      state.portfolio === null ||
      state.state !== "loading" // ||
    ) {
      if (args.reset) commit("setState", "loading");
      let url = `${rootState.apiEndpoint}web/v1/`;
      if (args.metaPortfolioUuid) {
        url += `meta-portfolios/${args.metaPortfolioUuid}/artworks.json?period=5`;
      } else {
        url += `portfolios/${args.portfolioId}/artworks.json?period=5`;
      }
      if (!args.reset && state?.portfolio?.latest_update) {
        url += `&updated_since=${state.portfolio.latest_update}`;
      } else {
        args.reset = true;
      }
      fetch(url, {
        ...rootState.fetchOptions,
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (args.reset)
            commit("setPortfolio", {
              portfolio: data.data,
              status: data.meta.portfolio_upload_status,
            });
          if (!args.reset)
            commit("patchPortfolio", {
              portfolio: data.data,
              status: data.meta.portfolio_upload_status,
            });
        })
        .catch(() => {
          commit("setState", "error");
        });
    }
  },
  invite(
    { commit, rootState },
    params: {
      group_id: number;
      email: string;
    }
  ): void {
    fetch(
      `${rootState.apiEndpoint}web/v1/groups/${params.group_id}/group_memberships/invite.json`,
      {
        ...rootState.fetchOptions,
        method: "POST",
        body: JSON.stringify({ email: params.email }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        commit("addMember", data.data.group_membership);
      })
      .catch(() => {});
  },
  update({ commit, state, rootState }, params: IPortfolio): void {
    if (state.portfolio) {
      fetch(
        `${rootState.apiEndpoint}web/v1/${
          state.portfolio["is_meta_portfolio?"] ? "meta-portfolios" : "groups"
        }/${state.portfolio.uuid}.json`,
        {
          ...rootState.fetchOptions,
          method: "PATCH",
          body: JSON.stringify(
            state.portfolio["is_meta_portfolio?"]
              ? { meta_portfolio: params }
              : { group: params }
          ),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (state.portfolio && state.portfolio.uuid === data.data.uuid) {
            commit("patchPortfolio", { portfolio: data.data });
          }
        })
        .catch(() => {});
    }
  },
  removeGroupArtist({ commit, state, rootState }, groupArtist: IGroupArtist) {
    if (state.portfolio) {
      // Optimistic remove
      commit("removeGroupArtist", groupArtist.uuid);

      fetch(
        `${rootState.apiEndpoint}web/v1/groups/${state.portfolio.uuid}/group_artists/bulk-edit.json`,
        {
          ...rootState.fetchOptions,
          method: "PATCH",
          body: JSON.stringify({
            group_artist_uuids: [groupArtist.uuid],
            published: false,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) commit("addGroupArtist", groupArtist);
        })
        .catch(() => {
          commit("addGroupArtist", groupArtist);
        });
    }
  },
  bulkUpdate: async (
    { state, rootState },
    params: {
      uuids: string[];
      group_artist_artwork: {
        status?: string | null;
        created_year?: number;
        name?: string;
        medium?: string;
        size?: {
          height: number | null;
          width: number | null;
          depth: number | null;
          unit: string | null;
        };
        comments?: string;
      };
    }
  ) => {
    if (state.portfolio) {
      return fetch(
        `${rootState.apiEndpoint}web/v1/groups/${state.portfolio.uuid}/group_artist_artworks/bulk_update.json`,
        {
          ...rootState.fetchOptions,
          method: "PATCH",
          body: JSON.stringify(params),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.meta.status === "success") {
            return "success";
          }
          return "error";
        })
        .catch(() => "error");
    }

    return "error";
  },
  patchGroupArtist: async ({ commit, state, rootState }, ga: IGroupArtist) => {
    if (state.portfolio) {
      return fetch(
        `${rootState.apiEndpoint}web/v1/groups/${state.portfolio.uuid}/group_artists/${ga.uuid}.json`,
        {
          ...rootState.fetchOptions,
          method: "PATCH",
          body: JSON.stringify(ga),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.meta.status === "success") {
            commit("patchGroupArtist", data.data);
            return "success";
          }
          return "error";
        })
        .catch(() => "error");
    }

    return "error";
  },
  bulkDelete: async (
    { commit, state, rootState },
    params: {
      uuids: string[];
      hard: boolean;
    }
  ) => {
    if (state.portfolio) {
      return fetch(
        `${rootState.apiEndpoint}web/v1/groups/${state.portfolio.uuid}/group_artist_artworks/bulk_delete.json`,
        {
          ...rootState.fetchOptions,
          body: JSON.stringify(params),
          method: "DELETE",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.meta.status === "success") {
            return "success";
          }
          return "error";
        })
        .catch(() => "error");
    }
  },
};
