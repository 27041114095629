import Vuex, { StoreOptions } from "vuex";

import { actions } from "@/store/actions";
import { artist } from "@/store/artist";
import { artistArtworkPrices } from "@/store/artist-artwork-prices";
import { groupArtistArtworks } from "@/store/group-artist-artworks";
import { help } from "@/store/help";
import { input } from "@/store/input";
import { menu } from "@/store/menu";
import { mutations } from "@/store/mutations";
import { notifications } from "@/store/notifications";
import { IRootState } from "@/store/types";
import { portfolio } from "@/store/portfolio";
import { portfolios } from "@/store/portfolios";
import { user } from "@/store/user";

const store: StoreOptions<IRootState> = {
  actions,
  getters: {
    modals: (state) => {
      return state.modals;
    },
  },
  modules: {
    artist,
    artistArtworkPrices,
    groupArtistArtworks,
    help,
    input,
    menu,
    notifications,
    portfolio,
    portfolios,
    user,
  },
  mutations,
  state: {
    apiEndpoint: "",
    fileEndpoint: "",
    // @ts-ignore MSStream on window non standard property ...
    isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    proxyS3: false,
    modals: [],
    newContentAvailable: false,
    saveGuard: false,
    version: "2.0.0",
    fetchOptions: {
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        baggage: undefined,
        "sentry-trace": undefined,
      },
    },
  },
  strict: process.env.NODE_ENV !== "production",
};

export default new Vuex.Store<IRootState>(store);
