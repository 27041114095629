<template lang="pug">
#app.app(:class='appClass')
  .alert.bg-red-700.text-white(v-if='expiresAt')
    | Your account expires in {{ expiresAt }}.
  .alert.bg-red-700.text-white(v-if='dev')
    | You are using the dev environment!
  transition(name='slide-down')
    .alert.bg-red-700.text-white(v-if='!isOnline')
      | No Internet Connection
  .click-refresh(v-if='isNewContentAvailable')
    | A new version of Wondeur is available
    button.refresh-button(@click='handleRefreshClick')
      | refresh
  main-nav(v-if='user')
  main(
    :class='mainClasses'
    @click='hideFiltersAndMenu'
  )
    button(
      @click.stop='toggleMenu'
      class=`
        main-menu-button
        p-4 pb-5
        border-2 border-solid border-transparent
        rounded
        transition-colors duration-500 ease-in-out delay-75
        hover:bg-gray-100
        focus:outline-none focus:bg-gray-100 focus:border-blue-300
      `
      v-if='showMainMenuBtn'
      ref='menuBtn')
      span.line.line-1
      br
      span.line.line-2
      br
      span.line.line-3
      span.sr-only
        | Menu
    router-view(:key='routeKey')
    transition(appear name='artist-details-slide-in')
      group-artist-details(v-if='artistId')
  .modals
    component(v-for='(modal, index) in modals'
      :is='modal.name'
      :args='modal.args'
      :index='index'
      :key='modal.name + index')
</template>

<script lang="ts">
import { IUser } from "@/types/users";
import { defineComponent, defineAsyncComponent } from "vue";
import { IModal } from "@/store/types";
import { registerSW } from 'virtual:pwa-register';

const MainNav = defineAsyncComponent(() => import("@/components/MainNav.vue"));
const GroupArtistDetails = defineAsyncComponent(
  () => import("@/components/groups/GroupArtistDetails.vue"),
);
const AddArtistToGroupModal = defineAsyncComponent(
  () => import("@/components/modals/AddArtistToGroupModal.vue"),
);
const AddGroupMemberModal = defineAsyncComponent(
  () => import("@/components/modals/AddGroupMemberModal.vue"),
);
const AddImportSearchArtistModal = defineAsyncComponent(
  () => import("@/components/modals/AddImportSearchArtistModal.vue"),
);
const CsvUploadErrorModal = defineAsyncComponent(
  () => import("@/components/modals/CsvUploadErrorModal.vue"),
);
const ImportStatsModal = defineAsyncComponent(
  () => import("@/components/modals/ImportStatsModal.vue"),
);

export default defineComponent({
  components: {
    AddArtistToGroupModal,
    AddGroupMemberModal,
    AddImportSearchArtistModal,
    CsvUploadErrorModal,
    ImportStatsModal,
    GroupArtistDetails,
    MainNav,
  },
  data() {
    const retries: number = 0;
    const now: number = new Date().getTime();
    const isOnline: boolean = true;
    const nameGetter: string = "";
    const updateServiceWorker = registerSW({
      immediate: true,
      onNeedRefresh: () => {
        this.$store.commit("newContentAvailable", true);
      },
      onRegisteredSW(swUrl, r) {
        r && setInterval(async () => {
          if (!(!r.installing && navigator))
            return
      
          if (('connection' in navigator) && !navigator.onLine)
            return
      
          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              'cache': 'no-store',
              'cache-control': 'no-cache',
            },
          })
      
          if (resp?.status === 200)
            await r.update()
        }, 60 * 60 * 1000)
      },
    });

    return {
      nameGetter,
      isOnline,
      now,
      retries,
      updateServiceWorker,
    };
  },
  computed: {
    routeKey(): string {
      return (
        "key-" +
        (this.$route.name || "").toString() +
        JSON.stringify(this.$route.params)
      );
    },
    showMainMenuBtn(): boolean {
      return this.user && !this.$route.query.row && !this.$route.query.column;
    },
    appClass(): string {
      let c: string = "app ";
      if (this.dev || this.isNewContentAvailable || !this.isOnline) {
        c += "dev ";
      }
      return c;
    },
    menuVisible(): boolean {
      return this.$store.getters["menu/menuVisible"];
    },
    filtersVisible(): boolean {
      return this.$store.getters["menu/filtersVisible"];
    },
    mainClasses(): string {
      return `main ${this.menuClasses}`;
    },
    menuClasses(): string {
      return this.$store.getters["menu/menuClasses"];
    },
    groupArtistId(): string | (string | null)[] | null {
      return this.$route.query.group_artist_id
        ? this.$route.query.group_artist_id
        : null;
    },
    artistId(): string | (string | null)[] | null {
      return this.$route.query.artist_id;
    },
    dev(): boolean {
      return this.$store.state.apiEndpoint === "https://api.wondeur.ai/" ||
        this.$store.state.apiEndpoint ===
          "https://api.wondeurai.deloittesolutions.lu/"
        ? false
        : true;
    },
    user(): IUser {
      return this.$store.getters["user/user"];
    },
    screenName(): string {
      // Dynamically get the screen name if we have a getter
      if (this.nameGetter && this.$store.getters[this.nameGetter]) {
        if (typeof this.$store.getters[this.nameGetter] === "function") {
          return this.$store.getters[this.nameGetter](this.$route.params.slug);
        } else {
          return this.$store.getters[this.nameGetter];
        }
      }

      return "…";
    },
    modals(): IModal[] {
      return this.$store.getters.modals;
    },
    isNewContentAvailable(): boolean {
      return this.$store.state.newContentAvailable;
    },
    timeDiff(): number | null {
      if (this.user && this.user.expires_at) {
        const expiresAt = Date.parse(this.user.expires_at);
        return (expiresAt - this.now) / 1000;
      }
      return null;
    },
    expiresAt(): string {
      if (this.timeDiff) {
        const days = Math.floor(this.timeDiff / 86400);
        const hours = Math.floor(this.timeDiff / 3600) % 24;
        const minutes = Math.floor(this.timeDiff / 60) % 60;
        const seconds = Math.floor(this.timeDiff) % 60;
        if (days > 28) {
          return "";
        }
        if (days > 1) {
          return `about ${days} days`;
        }
        if (hours > 1) {
          return `about ${hours} hours`;
        }
        if (minutes > 1) {
          return `about ${minutes} minutes`;
        }
        return `about ${seconds} seconds`;
      }
      return "";
    },
  },
  beforeMount() {
    this.$store.commit("setSentryTraceHeaders");
  },
  mounted(): void {
    if (window.location.pathname === "/index.html") {
      this.$router.push({ name: "home" });
    }
    // Update the online status icon based on connectivity
    window.addEventListener("online", this.updateIndicator);
    window.addEventListener("offline", this.updateIndicator);
    this.updateIndicator();
  },
  methods: {
    async fetchUserInfo() {
      const success: boolean = await this.$store.dispatch("user/fetchData");
      if (success) {
        this.retries = 0;
        if (this.$route.meta?.entryUrl === true) {
          this.$router.push({ name: "home" });
        }
      } else {
        if (this.retries > 10) {
          this.$router.push({ name: "login" });
        }
        this.retries += 1;
        window.setTimeout(() => {
          this.fetchUserInfo();
        }, 10000 * this.retries);
      }
    },
    setPageTitle(): void {
      let title: string = (this.$route.meta?.title as string) || "…";

      // Store the name getter
      if (title) {
        const matches: string[] | null = title.match(/%[\w/]+%/);
        if (matches && matches.length > 0) {
          this.nameGetter = matches[0].replace(/%/g, "");
        }
      }

      // Use the name getter to find the screen name
      if (title && this.screenName) {
        title = title.replace(/(%[\w/]+%|…)/, this.screenName);
      } else if (title) {
        title = title.replace(/%[\w/]+%/, "…");
      }

      // Update the doc title
      document.title = `${title} | Wondeur AI`;
    },
    toggleMenu(): void {
      this.$store.commit("menu/toggleMenu");
      (this.$refs.menuBtn as HTMLElement).blur();
    },
    toggleFilters(): void {
      this.$store.commit("menu/toggleFilters");
      (this.$refs.filterBtn as HTMLElement).blur();
    },
    hideFiltersAndMenu(): void {
      if (this.filtersVisible)
        this.$store.commit("menu/setFiltersVisible", false);
      if (this.menuVisible) this.$store.commit("menu/setMenuVisible", false);
    },
    updateIndicator(): void {
      navigator.onLine ? (this.isOnline = true) : (this.isOnline = false);
    },
    handleRefreshClick(): void {
      this.$store.commit("newContentAvailable", false);
      this.updateServiceWorker?.();
    },
    onRouteChange(to: object, from: object): void {
      this.setPageTitle();
    },
    onAristNameChange(to: object, from: object): void {
      // Watch for the screen name to change & then update the page title
      // IE when the data has loaded that has the screen name variable
      this.setPageTitle();
    },
    onTimeDiffChange(): void {
      if (this.timeDiff && this.timeDiff <= 0) {
        this.$router.push({ name: "logout" });
      } else if (this.timeDiff) {
        setTimeout(
          () => {
            this.now = new Date().getTime();
          },
          this.timeDiff * 1000 * 0.25,
        );
      }
    },
    onIsOnlineChanged(newVal: boolean, oldVal: boolean) {
      if (newVal && !oldVal) {
        this.fetchUserInfo();
      }
    },
  },
  watch: {
    $route: [
      {
        handler: "onRouteChange",
      },
    ],
    screenName: [
      {
        handler: "onAristNameChange",
      },
    ],
    timeDiff: [
      {
        handler: "onTimeDiffChange",
      },
    ],
    isOnline: [
      {
        handler: "onIsOnlineChanged",
      },
    ],
  },
});
</script>

<style lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>

<style lang="stylus">
@import 'styles/var';
@import 'styles/base';

.app {
  max-width: calc(100vw - 4em);
  margin: 0 auto;
  position: relative;
}

.app {
  &::after {
    content: '';
    height: env(safe-area-inset-top);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #202B34;
    opacity: 0.5;
    z-index: 1000;
  }
}

.dev .menu-is-hidden.filters-are-hidden {
  .filters-button, .main-menu-button {
    top: calc(env(safe-area-inset-top) + 2.2rem);
  }
}

@media(min-width: 1024px) {
  .dev .menu-is-hidden.filters-are-hidden {
    .filters-button, .main-menu-button {
      top: env(safe-area-inset-top);
    }
  }
}

.menu-is-hidden.filters-are-hidden {
  .filters-button, .main-menu-button {
    top: env(safe-area-inset-top);
  }
  .main-menu-button {
    left: 0.25rem;
  }
  .filters-button {
    right: 0.25rem;
  }
}

.main, .previous {
  position: fixed;
  height: 100%;
  margin: 0 auto;
  left: 50%;

  @media (min-width: 1024px) {
    height: 100vh;
    top: 0;
  }

  @media (min-width: 1800px) {
    border-radius: 0.125em 0.125em 0 0;
    height: calc(100vh - 2em);
    max-width: calc(100vw - 4em);
    top: 2em;
  }
}

.main {
  background-color: $background-colour;
  t: transform;
  will-change: transform;
  z-index: 10;
  transform: translateX(-50%);

  @media (min-width: 1024px) {
    border-style: solid;
    border-color: $border-colour;
    border-width: 1px 1px 0 1px;
  }

  &.menu-is-visible {
    transform: translate(calc(15em - 50%), 4em);
  }

  &.filters-are-visible {
    transform: translate(calc(-17em - 50%), 4em);

    @media (max-width: 649px) {
      transform: translate(calc(-100vw + 4em - 50%), 4em);
    }
  }
}

.filters-button {
  z-index: 6000;
}

.main-menu-button {
  z-index: 4000;
}

.filters-button, .main-menu-button {
  right: 0.5em;
  line-height: 0.5em;
  position: absolute;
  top: 0;
  transform: scale(0.75);
  width: 4em;
  transition: all 480ms eas-in-out;

  &.main-menu-button {
    left: -0.5rem;
    text-align: left;
    @media (min-width: 768px) {
      left: 0;
    }
  }

  &.filters-button {
    right: -0.5rem;
    text-align: right;
    @media (min-width: 768px) {
      right: 0.5rem;
    }
  }

  &:hover {
    .line-1 {
      animation-name: menu-line-1;
    }

    .line-2 {
      animation-name: menu-line-2;
    }

    .line-3 {
      animation-name: menu-line-3;
    }
  }

  .line {
    animation-duration: 2880ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 2;
    background-color: $text-colour;
    border-radius: 0.125em;
    display: inline-block;
    height: 0.25em;
    will-change: width;

    &.line-1 {
      width: 2em;
    }

    &.line-2 {
      width: 1.325em;
    }

    &.line-3 {
      width: 0.75em;
    }
  }
}

.alert {
  padding: 0.125em 1em;
  font-size: 0.75rem;
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  right: 0;
  text-align: center;
  white-space: nowrap;
  z-index: 9000;

  @media (min-width: 1024px) {
    border-radius: 0 0 0.25em 0.25em;
    left: 50%;
    right: 15%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 1600px;
  }
}

.click-refresh {
  background-color: #1f2a34;
  color: white;
  padding: 0.5em 1em;
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  right: 0;
  text-align: center;
  white-space: nowrap;
  z-index: 9000;

  @media (min-width: 1024px) {
    border-radius: 0 0 0.25em 0.25em;
    left: 15%;
    right: 15%;
  }

  @media (max-width: 380px) {
    font-size: 0.8em;
  }
}

.refresh-button {
  color: white;
  font-weight: 500;
  padding: 0 0.5em;
}

@keyframes menu-line-1 {
  0% {
    width: 2em;
  }

  50% {
    width: 0.75em;
  }

  100% {
    width: 2em;
  }
}

@keyframes menu-line-2 {
  0% {
    width: 1.325em;
  }

  25% {
    width: 2em;
  }

  75% {
    width: 0.75em;
  }

  100% {
    width: 1.325em;
  }
}

@keyframes menu-line-3 {
  0% {
    width: 0.75em;
  }

  50% {
    width: 2em;
  }

  100% {
    width: 0.75em;
  }
}
</style>
